<template>
    <div class="fix-full">
        <div class="absolute-full">
            <div class="mask" @click="closeWindow"></div>
            <div class="window-container">
                <div class="avatar-box">
                    <img :src="avatar"
                         class="avatar-img"
                         :onerror="defaultAvatar">
                </div>
                <h2 class="table-title">
                    <label>工号</label>
                    <strong class="staff-no">{{staffNo}}</strong>
                </h2>
                <div class="scroll-container">
                    <div class="technician-detail">
                        <technician-detail-table :tableData="techWorkData"></technician-detail-table>
                    </div>
                    <div class="dot-clock">
                        <h2 class="table-title">点钟情况</h2>
                        <technician-detail-table :tableData="dotClockData"></technician-detail-table>
                    </div>
                    <div class="dot-clock">
                        <h2 class="table-title">预约情况</h2>
                        <technician-detail-table :tableData="reverseData"></technician-detail-table>
                    </div>
                </div>
                <div class="btn-box"
                     @click="closeWindow">
                    <button class="close-btn">我知道了</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name:"technician-detail",
		props:{
			techDetail:{
				type:Object,
				default:() => {}
			}
		},
		data(){
			return {
				techWorkData:{
					headList:[
						{name:'房号',filed:'room_no',width:''},
						{name:'服务项目',filed:'project_name',width:'2rem'},
						{name:'上钟方式',filed:'work_type',width:''},
						{name:'余时(分)',filed:'last_time',width:''},
					],
					dataList:[],
				},
				dotClockData:{
					headList:[
						{name:'房号',filed:'room_no'},
						{name:'服务项目',filed:'project_name',width:'2.6rem'},
						{name:'等待时间',filed:'wait_time'},
					],
					dataList:[],
				},
				reverseData:{
					headList:[
						{name:'预约人',filed:'client_name'},
						{name:'电话号码',filed:'phone',width:'2.6rem'},
						{name:'抵达时间',filed:'arrival_time'},
					],
					dataList:[],
				},
			}
		},
		computed:{
			defaultAvatar(){
				return 'this.src="' + require('@/assets/images/detail-avatar-detail.png') + '"'
			},
            avatar(){
				let {techDetail} = this
				return !!techDetail&&!!techDetail.avatar?techDetail.avatar:''
            },
			staffNo(){
				let {techDetail} = this
				return !!techDetail&&!!techDetail.staff_no?techDetail.staff_no:''
            },
		},
		watch:{
			techDetail:{
				deep:true,
				immediate:true,
				handler(newVal){
					if(! newVal){
						return
					}
					this.getTableData()
				},
			}
		},
		components:{
			'technician-detail-table':() => import('./technician-detail-table')
		},
		methods:{
			getTableData(){
				let {techDetail,reverseData,dotClockData,techWorkData} = this
				let {room_no,project_name,work_type,last_time,dot_clock_list,reserve_list} = techDetail
				let techWork = {
					room_no,
					project_name,
					work_type,
					last_time
				}
				techWorkData.dataList.push(techWork)
				dotClockData.dataList = dot_clock_list
				reverseData.dataList = reserve_list
			},
			closeWindow(){
				this.$store.commit('updateShowTechDetail',false)
				this.$store.commit('updateTechDetail',null)
			}
		}
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .fix-full{
        @extend %fix-full;
        z-index:2;
    }

    .absolute-full{
        @extend %full;
        @extend %flex-center-row;
    }

    .mask{
        @extend %mask;
    }

    .window-container{
        position:absolute;
        left:75px;
        right:75px;
        z-index:1;
        background:#FFFFFF;
        border-radius:10px;
        padding:0 30px;
    }

    .avatar-box{
        height:105px;
        position:relative;
    }

    .avatar-img{
        width:170px;
        height:170px;
        border-radius:50%;
        position:absolute;
        top:-70px;
        left:50%;
        margin:0 0 0 -85px;
    }

    .scroll-container{
        max-height:52vh;
        @extend %scroll-container;
    }

    .table-title{
        margin:10px 0 0;
        line-height:65px;
        height:65px;
        font-size:24px;
        color:#333333;
    }

    .staff-no{
        font-size:32px;
    }

    .btn-box{
        margin:40px 0;

        .close-btn{
            display:inline-block;
            background:$high-light-color;
            color:#FFFFFF;
            width:100%;
            height:75px;
            line-height:75px;
            font-size:28px;
            text-align:center;
            border-radius:10px;
            font-weight:bold;
        }
    }
</style>
